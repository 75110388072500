import { mapGetters, mapActions } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const BannerItem = () => import(/* webpackChunkName = "c-banner-item" */'@/components/BannerItem')
const AddBannerForm = () => import(/* webpackChunkName = "c-add-banner-form" */'@/components/AddBannerForm')

export default {
  name: 'Banner',
  mixins: [authMixin],
  components: {
    BannerItem,
    AddBannerForm
  },
  data () {
    return {
      visibleAddForm: false
    }
  },
  created () {
    this.getBannersAction({
      success: () => {}
    })
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapGetters('banner', ['banners'])
  },
  methods: {
    ...mapActions('banner', ['getBannersAction', 'deleteBannersAction']),
    handleSuccessDelete () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
    },
    handleDeleteBanner (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteBannersAction({
            success: this.handleSuccessDelete,
            data: {
              id: id,
              token: this.token
            }
          })
        }
      })
    },
    toggleAddForm () {
      this.visibleAddForm = !this.visibleAddForm
    }
  }
}
